import React, { FunctionComponent, useEffect, useState } from "react"
import Layout from "../components/layout"
import { Flex, Box, Image, Text, Divider } from "@chakra-ui/react"
import MorePostsTiles from "../components/MorePostsTiles/MorePostsTiles"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import { cardRadius, colors, shadows } from "../theme/theme"
// @ts-ignore
import ProfilePicture from "../assets/images/profile_picture2.jpg"
import { myAge } from "../utils/globalVariables"
import { Link } from "gatsby"
import { twitterProfile } from "../utils/routes"
import NewsletterSignup from "../components/NewsletterSignup/NewsletterSignup"
import LoadingSkeleton from "../components/LoadingSkeleton"
import ContactSection from "../components/LandingPage/ContactSection"
import CustomH2 from "../components/LandingPage/CustomH2"

const mobileSwitch = "720px"

const StyledFlex = styled(Flex)`
  margin-right: 0.5rem;
  width: 50%;
  @media (max-width: ${mobileSwitch}) {
    width: 100%;
    margin-right: 0;
  }
`

const StyledContainer = styled(Flex)`
  min-height: 12rem;
  background-color: ${colors.white};
  border-radius: ${cardRadius};
  box-shadow: ${shadows.containerShadow};
  padding: 1.5rem;
  width: 80%;
  flex-direction: row;
  @media (max-width: ${mobileSwitch}) {
    width: 92%;
    flex-direction: column;
  }
`

const StyledBackgroundBox = styled(Box)`
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue2},
    ${colors.blue.blue5}
  );
  border-radius: ${cardRadius};
  box-shadow: ${shadows.containerShadow};
  width: 100%;
  height: 25rem;
`

const StyledText = styled(Text)`
  margin: 0.5rem 0;
  line-height: 1.5rem;
  color: ${colors.grey.mediumDark};
`

const TextContainer = styled(Box)`
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: clip;
  margin-left: 0.5rem;
  width: 50%;
  @media (max-width: ${mobileSwitch}) {
    margin-left: 0;
    width: 100%;
  }
`

export const About: FunctionComponent = ({}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <>
      <Seo
        title="About Me | HendrikGruber.com"
        meta={[
          {
            name: "About Me",
            content:
              "A short biography of Hendrik Gruber and the contents of this blog.",
          },
        ]}
      />
      <Layout styles={{ justifyContent: "center" }}>
        <Flex flexDir="column" alignItems="center" justifyContent="center">
          <StyledBackgroundBox />
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="-22rem"
          >
            <StyledContainer
              verticalAlign="middle"
              alignContent="center"
              alignItems="center"
            >
              <StyledFlex
                height="100%"
                alignContent="center"
                alignItems="center"
              >
                <Image
                  src={ProfilePicture}
                  title="Hendrik Gruber Profile Picture"
                  objectFit="cover"
                  width="100%"
                  maxHeight="25rem"
                  objectPosition="center center"
                  borderRadius={cardRadius}
                  boxShadow={shadows.containerShadow}
                  marginBottom="0"
                  style={{ display: `${imageLoaded ? "" : "none"}` }}
                  onLoad={() => {
                    setImageLoaded(true)
                  }}
                />

                {!imageLoaded && (
                  <LoadingSkeleton
                    width="100%"
                    height="25rem"
                    radius="cardRadius"
                    styles={{ boxShadow: shadows.containerShadow }}
                  />
                )}
              </StyledFlex>
              <TextContainer>
                <StyledText>
                  <Text>Hey, my name's</Text>
                  <Text
                    fontSize="1.5rem"
                    color={colors.grey.dark}
                    padding="0.25rem 0"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    Hendrik.
                  </Text>
                  <Text>
                    I'm {myAge} years old and at that age where I'm not entirely
                    sure what to do with my life. I enjoy developing websites in
                    my free time and also enjoy irregularly writing blog posts
                    about anything interesting to me.
                  </Text>
                  <Text>
                    If you're in need of a new website for your small business,
                    club, or hobby, then check out my{" "}
                    <Link to="/hire" style={{ color: colors.blue.blue2 }}>
                      hire me page
                    </Link>
                    .
                  </Text>
                  <Text>
                    I live in a German city called{" "}
                    <Link
                      to="https://goo.gl/maps/rpCmZbtU7cL848gLA"
                      style={{ color: colors.blue.blue2 }}
                      target="_blank"
                    >
                      Darmstadt
                    </Link>
                    . I'm currently pursuing my Master's Degree at the
                    University of Applied Sciences. I also worked as a web
                    developer for 2 years at{" "}
                    <Link
                      to="https://www.qbeyond.de/"
                      style={{ color: colors.blue.blue2 }}
                      target="_blank"
                    >
                      q.beyond AG
                    </Link>{" "}
                    and gained a lot of professional experience in the industry.
                  </Text>
                  <Text>Feel free to get in touch with me!</Text>
                </StyledText>
              </TextContainer>
            </StyledContainer>
          </Flex>
          <Box marginTop="2rem">
            <ContactSection />
          </Box>
          <Divider margin="2rem 0" />
          <Box width="100%">
            <Box marginBottom="0.75rem">
              <CustomH2 text="Check Out My Blog" />
            </Box>
            <MorePostsTiles totalPostsToShow={3} />
          </Box>
          <NewsletterSignup />
        </Flex>
      </Layout>
    </>
  )
}

export default About
